import { useTranslations } from "next-intl";
import {
  LuCheckCircle,
  LuHourglass,
  LuMinusCircle,
  LuPackage,
  LuThumbsDown,
} from "react-icons/lu";

import { Badge } from "@acme/ui";

const BadgeDesc = ({ status }: { status: string }) => (
  <span className="ml-1">{status}</span>
);

type Tf = ReturnType<typeof useTranslations<"patient.list">>;

export type StatusType =
  | "NO_UPLOADS"
  | "PENDING"
  | "NEW"
  | "REJECTED"
  | "DONE"
  | "ARCHIVED";

// BadgeStatusProps
type BadgeStatusProps = {
  status: StatusType;
  t: Tf;
};

export default function BadgeStatus({ status }: BadgeStatusProps) {
  const t = useTranslations("patient.list");

  if (status === "NO_UPLOADS") {
    return (
      <Badge variant="secondary">
        <LuMinusCircle /> <BadgeDesc status={t("status.noUploads")} />
      </Badge>
    );
  } else if (status === "PENDING") {
    return (
      <Badge variant="secondary">
        <LuHourglass /> <BadgeDesc status={t("status.pending")} />
      </Badge>
    );
  } else if (status === "NEW") {
    return (
      <Badge>
        <LuPackage /> <BadgeDesc status={t("status.new")} />
      </Badge>
    );
  } else if (status === "REJECTED") {
    return (
      <Badge variant="destructive">
        {" "}
        <LuThumbsDown /> <BadgeDesc status={t("status.rejected")} />
      </Badge>
    );
  } else if (status === "DONE") {
    return (
      <Badge variant="outline">
        <LuCheckCircle /> <BadgeDesc status={t("status.done")} />
      </Badge>
    );
  }

  return <Badge>?</Badge>;
}
