import React from "react"

import { Input as BaseInput, InputProps } from "../components/ui/input"

export interface NewInputProps extends Omit<InputProps, "value"> {
  value?: InputProps["value"] | null
}

export const Input = React.forwardRef<HTMLInputElement, NewInputProps>(
  (props, ref) => {
    return <BaseInput {...props} value={props.value ?? undefined} ref={ref} />
  }
)

Input.displayName = "Input"
