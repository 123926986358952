"use client";

import { useTranslations } from "next-intl";

import { useToast } from "@acme/ui";

import { columns } from "./columns";
import { DataTable } from "./data-table";

export default function Home() {
  const tList = useTranslations("patient.list");
  const t = useTranslations("patient");

  const { toast } = useToast();

  return (
    <DataTable
      columns={columns({
        t: tList,
        doAction: (act) => {
          switch (act.type) {
            case "copy-data":
              navigator.clipboard
                .writeText(act.data)
                .then(() => {
                  toast({
                    variant: "default",
                    title: t("toastCopy.success.title"),
                    description: t("toastCopy.success.description.dataCopied"),
                  });
                })
                .catch((e) => {
                  console.error("Error", e);
                  toast({
                    variant: "destructive",
                    title: t("toastCopy.error.title"),
                    description: t("toastCopy.error.description"),
                  });
                });
              break;
            case "add-picture":
              /// TODO: Add picture
              toast({
                variant: "default",
                title: "Picture added.",
                description: "The patient picture was added.",
              });
              break;
            default:
              break;
          }
        },
      })}
    />
  );
}
