class ErrorHttp extends Error {
  protected status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export class ErrorNotFound extends ErrorHttp {
  constructor(message = "Not Found") {
    super(message, 404);
    this.name = "ErrorNotFound";
  }
}
