"use client";

import { log } from "console";
import Link from "next/link";
import {
  CheckIcon,
  ClockIcon,
  CrossCircledIcon,
  FileMinusIcon,
} from "@radix-ui/react-icons";
import { type ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal } from "lucide-react";
import { type useTranslations } from "next-intl";

import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acme/ui";

import BadgeStatus, { StatusType } from "./badge-desc";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type PatientRow = {
  id: string;
  status: StatusType;
  email: string;
  name: string;
  partner: string;
};

type ActionType =
  | { type: "copy-data"; data: string }
  | { type: "navigate-to"; url: string }
  | { type: "add-picture"; patientId: string }
  | { type: "measure-picture"; patientId: string; photoId: string }
  | { type: "request-retake"; patientId: string; photoIds: string[] };

type Tf = ReturnType<typeof useTranslations<"patient.list">>;

type ColumnsProps = {
  doAction: (actionDate: ActionType) => void;
  t: Tf;
};

export const columns: (props: ColumnsProps) => ColumnDef<PatientRow>[] = (
  props,
) => [
  // {
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={table.getIsAllPageRowsSelected()}
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },

  {
    accessorKey: "status",
    header: "Status",
    enableHiding: false,
    cell: ({ row }) => {
      // return null;
      return (
        <Link key={row.original.id} href={`/patient/${row.original.id}`}>
          <BadgeStatus status={row.original.status} t={props.t} />
        </Link>
      );
    },
  },
  {
    accessorKey: "name",
    enableHiding: false,
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {props.t("name")}

          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <Link key={row.original.id} href={`/patient/${row.original.id}`}>
          {row.original.name}
        </Link>
      );
    },
  },
  {
    accessorKey: "email",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {props.t("email")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <Link key={row.original.id} href={`/patient/${row.original.id}`}>
          {row.original.email}
        </Link>
      );
    },
  },
  {
    accessorKey: "partner.name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {props.t("partner")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  /*
  {
    accessorKey: "amount",
    header: () => <div className="text-right">Amount</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("amount"));
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);

      return <div className="text-right font-medium">{formatted}</div>;
    },
  },
  */

  {
    id: "actions",
    enableHiding: false,

    cell: ({ row }) => {
      const patient = row.original;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{props.t("actions.label")}</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                props.doAction({ type: "copy-data", data: patient.id });
              }}
            >
              {props.t("actions.copyData")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {/* <DropdownMenuItem
              onClick={() => {
                props.doAction({
                  type: "add-picture",
                  patientId: patient.id,
                });
              }}
            >
              Add Picture
            </DropdownMenuItem> 
            <DropdownMenuItem>Measure existing picture</DropdownMenuItem>
            <DropdownMenuItem>Request retake</DropdownMenuItem>
            */}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
