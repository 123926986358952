import { cn } from "@acme/utils"

import { SpinnerIcon } from "./spinner-icon"

export function LoadingSpinner({
  className,
  loadingText,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & { loadingText: string }) {
  return (
    <div className={cn("", className)} {...props}>
      <div role="status">
        <SpinnerIcon />
        <span className="sr-only">{loadingText}</span>
      </div>
    </div>
  )
}
